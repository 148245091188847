import { useState } from "react";
import { Menu, X } from "lucide-react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';

export default function Navbar({ links = defaultLinks() }) {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <header className="w-full px-4 lg:px-6 h-16 flex items-center bg-green-900 justify-between shadow-lg animate-fadeIn sticky top-0 z-50">
      {/* Logo */}
      <Link className="flex items-center" to="/">
        <img
          src="/01-images/logo.webp"
          alt="Logo Colegio Nuevo Milenio"
          width={100}
          height={100}
          className="mr-4 hover:rotate-3 transition-transform duration-300"
        />
      </Link>

      {/* Menú para pantallas grandes */}
      <nav className="hidden sm:flex gap-6 sm:gap-8 animate-slideDown">
        {links.map((link) => (
          <NavLink key={link.to} to={link.to} text={link.text} />
        ))}
      </nav>

      {/* Botón del menú para pantallas pequeñas */}
      <button
        className="sm:hidden text-beige-100 hover:text-white transition-colors duration-200 focus:outline-none hover:scale-110"
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
      >
        {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
      </button>

      {/* Menú desplegable para dispositivos móviles */}
      {isMobileMenuOpen && (
        <MobileMenu links={links} onClose={() => setIsMobileMenuOpen(false)} />
      )}
    </header>
  );
}

function defaultLinks() {
  return [
    { to: "/", text: "Inicio" },
    { to: "/becas", text: "Becas 2025" },
    { to: "/documentos", text: "Documentos" },
    { to: "/lista-de-utiles", text: "Lista de útiles" },
    // { to: "/galeria", text: "Galería" },
  ];
}

function NavLink({ to, text }) {
  return (
    <Link
      className="text-base font-medium text-beige-100 hover:text-white hover:underline underline-offset-4 transition-all duration-200 hover:scale-105"
      to={to}
    >
      {text}
    </Link>
  );
}

function MobileMenu({ links, onClose }) {
  return (
    <div className="fixed inset-0 z-50 flex justify-end">
      <div className="bg-green-900 text-white w-64 max-w-full p-6 shadow-lg transform transition-transform duration-300 ease-in-out translate-x-0">
        <nav className="flex flex-col mt-8 space-y-4">
          {links.length > 0 ? (
            links.map((link) => (
              <NavLink key={link.to} to={link.to} text={link.text} />
            ))
          ) : (
            <p>Cargando...</p>
          )}
        </nav>
        <div className="flex justify-center mt-8 space-x-4">
          <a href="https://web.facebook.com/colegionmva" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
            <FontAwesomeIcon icon={faFacebookF} size="lg" />
          </a>
          <a href="https://www.instagram.com/colegionmva" target="_blank" rel="noopener noreferrer" className="text-white hover:text-gray-300">
            <FontAwesomeIcon icon={faInstagram} size="lg" />
          </a>
        </div>
      </div>
      <div className="flex-1 bg-black bg-opacity-50" onClick={onClose}></div>
    </div>
  );
}