const supplies = [
    {
      id: 1,
      title: "Lista de Útiles - Pre Kinder",
      course: "Pre Kinder",
      fileUrl: "/05-lista-de-utiles/Lista de útiles Kinder 2025 OK.pdf",
    },
    {
      id: 2,
      title: "Lista de Útiles - Kinder",
      course: "Kinder",
      fileUrl: "/05-lista-de-utiles/Lista de útiles Prekinder 2025 OK.pdf",
    },
    {
      id: 3,
      title: "Lista de Útiles - 1ro Basico",
      course: "1ro basico",
      fileUrl: "/05-lista-de-utiles/OK Lista útiles  1ºbásico 2025 .pdf",
    },
    {
      id: 4,
      title: "Lista de Útiles - 2do Basico",
      course: "2do Basico",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles  2ºbásico 2025.pdf",
    },
    {
      id: 5,
      title: "Lista de Útiles - 3ro Basico",
      course: "3ro Basico",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles 3° Básicos 2025 .pdf",
    },
    {
      id: 6,
      title: "Lista de Útiles - 4to Basico",
      course: "4to Basico",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles. 4º básico 2025.pdf",
    },
    {
      id: 7,
      title: "Lista de Útiles - 5to Basico",
      course: "5to Basico",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles 5º básico 2025.pdf",
    },
    {
      id: 8,
      title: "Lista de Útiles - 6to Basico",
      course: "6to Basico",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles 6° Básicos  2025 .pdf",
    },
    {
      id: 9,
      title: "Lista de Útiles - 7mo Basico",
      course: "7mo Basico",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles  7ºbásico 2025.pdf",
    },
    {
      id: 10,
      title: "Lista de Útiles - 8vo Basico",
      course: "8vo Basico",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles 8°básico 2025.pdf",
    },
    {
      id: 11,
      title: "Lista de Útiles - 1ro Medio",
      course: "1ro Medio",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles. 1ºMedio 2025.pdf",
    },
    {
      id: 12,
      title: "Lista de Útiles - 2do Medio",
      course: "2do Medio",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles. 2ºMedio  2025 .pdf",
    },
    {
      id: 13,
      title: "Lista de Útiles - 3ro Medio",
      course: "3ro Medio",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles 3°Medio 2025 .pdf",
    },
    {
      id: 14,
      title: "Lista de Útiles - 4to Medio",
      course: "4to Medio",
      fileUrl: "/05-lista-de-utiles/OK Lista de útiles 4°Medios 2025.pdf",
    },
    // ... resto de las listas ...
  ];
  
  export default supplies;