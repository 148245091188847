import React from 'react';
import { Download } from 'lucide-react';
import Button from '@/components/Button';

const Becas = () => {
  return (
    <div className="flex flex-col min-h-screen bg-beige-50">
      {/* Contenido principal */}
      <main className="flex-1 w-full flex flex-col items-center">
        <MainContent />
      </main>
    </div>
  );
};

const MainContent = () => {
  return (
    <section className="flex-1 w-full flex flex-col items-center py-16 md:py-24 lg:py-32">
      <div className="container px-4 md:px-6 mx-auto text-center">
        <h1 className="text-3xl font-bold tracking-tighter text-green-900 sm:text-4xl md:text-5xl lg:text-5xl mb-8">
          Programa de Becas 2025
        </h1>
        <p className="max-w-[800px] text-green-800 md:text-lg lg:text-lg xl:text-lg mb-16 mx-auto leading-relaxed">
          Nuestro programa de becas está diseñado para apoyar a la familia y brindarle la oportunidad 
          de continuar su educación en el Colegio Nuevo Milenio.
        </p>
        <ScholarshipResults />
      </div>
    </section>
  );
};

const ScholarshipResults = () => {
  return (
    <div className="bg-white p-12 rounded-2xl shadow-2xl max-w-3xl mx-auto transform hover:scale-[1.01] transition-all duration-300">
      <h2 className="text-xl font-bold text-green-900 mb-6">
        Resultado de Becas
      </h2>
      <p className="text-green-700 text-base mb-4 leading-relaxed">
        Los resultados de las becas para el año 2025 ya están disponibles. 
        Para consultar si has sido beneficiado, por favor descarga el documento 
        de resultados haciendo clic en el botón a continuación.
      </p>
      <div className="flex justify-center">
        <Button
          variant="default"
          className="px-8 py-4 text-base shadow-lg hover:shadow-xl transition-all flex items-center justify-center space-x-3"
          onClick={() => window.open('/06-becas/resultados-becas-2025.pdf', '_blank')}
        >
          <Download className="h-5 w-5" />
          <span>Descargar Resultados de Becas</span>
        </Button>
      </div>
    </div>
  );
};

export default Becas;