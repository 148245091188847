import NuestraHistoriaCard from "./cards/NuestraHistoriaCard";
import MisionVisionCard from "./cards/MisionVisionCard";
import SellosEducativosCard from "./cards/SellosEducativosCard";

export default function ValuesSection({ openModal }) {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-white">
      <div className="container mx-auto px-4 md:px-6 text-center">
        <h2 className="text-3xl font-bold tracking-tight text-green-900 mb-8">
          Valores de Nuestro Colegio
        </h2>
        <div className="grid gap-10 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
          <NuestraHistoriaCard openModal={openModal} />
          <MisionVisionCard openModal={openModal} />
          <SellosEducativosCard openModal={openModal} />
        </div>
      </div>
    </section>
  );
}