const documents = [
    {
      id: 1,
      title: "Plan de gestión de convivencia escolar",
      category: "Convivencia",
      fileUrl: "/07-documentos/pgce-2023.pdf",
    },
    {
      id: 2,
      title: "Protocolo de acción ante gresiones físicas y/o amenazas",
      category: "Convivencia",
      fileUrl: "/07-documentos/paafa.pdf",
    },
    {
      id: 3,
      title: "Protocolo ante consumo de alcohol y drogas y situaciones relacionadas a tráfico y microtráfico",
      category: "Convivencia",
      fileUrl: "/07-documentos/pcadstm.pdf",
    },
    {
      id: 4,
      title: "Protocolo de maltrato físico de funcionario a estudiante y/o parvulo",
      category: "Convivencia",
      fileUrl: "/07-documentos/pmffep.pdf",
    },
    {
      id: 5,
      title: "Protocolo de maltrato, acoso escolar o violencia entre miembros de la comunidad educativa",
      category: "Convivencia",
      fileUrl: "/07-documentos/pmaevmce.pdf",
    },
    {
      id: 6,
      title: "Reglamento de evaluación, calificación y promoción",
      category: "Convivencia",
      fileUrl: "/07-documentos/ReglamentoDeEvaluacionActualizado2025.pdf",
    },
    {
      id: 7,
      title: "Reglamento interno de orden, higiene y seguridad",
      category: "Convivencia",
      fileUrl: "/07-documentos/riohs.pdf",
    },
    {
      id: 8,
      title: "Reglamento interno escolar",
      category: "Convivencia",
      fileUrl: "/07-documentos/RIE 2024 Actualizado.pdf",
    },
  ];
  
  export default documents;