const galleryItems = [
    {
      id: 1,
      title: "Título 1",
      image: "/04-galeria/paisaje.jpg",
      description: "Lorem ipsum lorem ipsum lorem ipsum"
    },
    {
      id: 2,
      title: "Título 2",
      image: "/04-galeria/paisaje.jpg",
      description: "Lorem ipsum lorem ipsum lorem ipsum"
    },
    {
      id: 3,
      title: "Título 3",
      image: "/04-galeria/paisaje2.jpg",
      description: "Lorem ipsum lorem ipsum lorem ipsum"
    },
    {
      id: 4,
      title: "Título 4",
      image: "/04-galeria/paisaje2.jpg",
      description: "Lorem ipsum lorem ipsum lorem ipsum"
    },
    {
      id: 5,
      title: "Título 5",
      image: "/04-galeria/paisaje.jpg",
      description: "Lorem ipsum lorem ipsum lorem ipsum"
    },
    // Agregar más imágenes según necesites
  ];
  
  export default galleryItems;