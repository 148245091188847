import React, { useState } from 'react';
import galleryItems from '@/data/gallery';
import Button from '@/components/Button';

const Galeria = () => {
  const [visibleCount, setVisibleCount] = useState(4);

  const loadMore = () => setVisibleCount((prevCount) => prevCount + 4);
  const showLess = () => setVisibleCount((prevCount) => Math.max(prevCount - 4, 4));

  return (
    <div className="flex flex-col min-h-screen bg-beige-50">
      <main className="flex-1 w-full flex flex-col items-center py-12 px-6 md:px-12">
        <h1 className="text-4xl font-bold text-green-900 mb-8 text-center">
          Galería
        </h1>
        
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
          {galleryItems.slice(0, visibleCount).map((item) => (
            <GalleryCard key={item.id} item={item} />
          ))}
        </div>

        <div className="mt-8 flex gap-4">
          {visibleCount < galleryItems.length && (
            <Button
              onClick={loadMore}
              variant="default"
            >
              Cargar más
            </Button>
          )}
          {visibleCount > 4 && (
            <Button
              onClick={showLess}
              variant="default"
            >
              Ver menos
            </Button>
          )}
        </div>
      </main>
    </div>
  );
};

// Componente GalleryCard
const GalleryCard = ({ item }) => {
  return (
    <div className="bg-white p-8 rounded-lg shadow-2xl">
      <h2 className="text-2xl font-bold mb-6 text-center text-green-900">
        {item.title}
      </h2>
      <div className="relative overflow-hidden rounded-lg mb-6">
        <div className="relative rounded-lg bg-white shadow-inner">
          <div className="aspect-[16/9] relative h-[400px]">
            <img 
              src={item.image} 
              alt={item.title} 
              className="absolute inset-0 w-full h-full object-cover rounded-lg transition-transform duration-300 hover:scale-105 shadow-lg"
            />
          </div>
        </div>
      </div>
      <p className="text-lg text-gray-700">{item.description}</p>
    </div>
  );
};

export default Galeria;