import React from 'react';
import { Link } from 'react-router-dom';
import { MapPin, Phone, Mail, Clock, Facebook, Instagram } from 'lucide-react';

const Footer = () => {
  return (
    <footer className="w-full py-6 bg-green-900 text-beige-50 animate-fadeIn">
      <div className="container mx-auto px-6 md:px-8 flex flex-col sm:flex-row items-center justify-between gap-4">
        <div className="flex flex-col text-sm">
          <h3 className="font-bold mb-2">CONTÁCTANOS</h3>
          <div className="flex items-center mb-1">
            <MapPin className="mr-2 h-4 w-4" />
            Calle Alcalde Rodolfo Galleguillos, Número 197, Villa Alemana.
          </div>
          <div className="flex items-center mb-1">
            <Phone className="mr-2 h-4 w-4" />
            +56 9 9997 3396 Secretaría
          </div>
          <div className="flex items-center mb-1">
            <Phone className="mr-2 h-4 w-4" />
            +56 9 8723 7809 Recepción
          </div>
          <div className="flex items-center mb-1">
            <Phone className="mr-2 h-4 w-4" />
            +56 9 9997 3482 Inspectoría
          </div>
          <div className="flex items-center mb-1">
            <Mail className="mr-2 h-4 w-4" />
            informaciones@nmva.cl
          </div>
          <div className="flex items-center">
            <Clock className="mr-2 h-4 w-4" />
            Lun a Vier: 8:00 - 13:30 Hrs
          </div>
        </div>
        
        <div className="flex flex-col text-sm">
          <h3 className="font-bold mb-2">DATOS BANCARIOS</h3>
          <div className="flex flex-col">
            <span>Cuenta Corriente Nro: 9747965-46</span>
            <span>Banco: Scotiabank Chile</span>
            <span>Rut: 65.154.531-5</span>
            <span>Fundación Educacional Nuevo Milenio</span>
            <span>recaudaciones@nmva.cl</span>
            <span>fono contacto: +56 9 8723 9659</span>
          </div>
        </div>
        
        <div className="flex gap-4 mt-4 sm:mt-0">
          <a 
            href="https://web.facebook.com/colegionmva" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-beige-50 hover:text-beige-100 transition-colors"
          >
            <Facebook className="w-6 h-6" />
          </a>
          <a 
            href="https://www.instagram.com/colegionmva" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="text-beige-50 hover:text-beige-100 transition-colors"
          >
            <Instagram className="w-6 h-6" />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;