import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from '@/components/Navbar';
import Footer from '@/components/Footer';
import Home from '@/pages/Home';
import QuienesSomos from '@/pages/QuienesSomos';
import Documentos from '@/pages/Documentos';
import Becas from '@/pages/Becas';
import ListaDeUtiles from '@/pages/ListaDeUtiles';
import Galeria from '@/pages/Galeria';

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/quienes-somos" element={<QuienesSomos />} />
          <Route path="/documentos" element={<Documentos />} />
          <Route path="/lista-de-utiles" element={<ListaDeUtiles />} />
          <Route path="/galeria" element={<Galeria />} />
          <Route path="/becas" element={<Becas />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;