export default function ValuesCard({ icon: Icon, title, description, onClick }) {
    return (
      <div className="flex flex-col items-center text-center p-8 rounded-xl border border-green-100 shadow-lg hover:shadow-xl transition-all duration-300">
        <Icon className="h-12 w-12 text-green-900 mb-4" />
        <h2 className="text-xl font-bold mb-2 text-green-800">{title}</h2>
        <p className="text-green-700 mb-4">{description}</p>
        <button
          className="bg-green-900 text-white hover:bg-green-800 px-4 py-2 rounded"
          onClick={onClick}
        >
          Ver más
        </button>
      </div>
    );
  }