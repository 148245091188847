import { useState } from 'react';
import Button from "@/components/Button";
import {
  ChevronDown,
  ChevronUp,
  ChevronLeft,
  ChevronRight
} from "lucide-react";
import departments from "@/data/departments";
import ValuesSection from "@/components/sections/ValuesSection";

export default function QuienesSomos() {
  const [expandedDepartment, setExpandedDepartment] = useState(null);
  const [currentPage, setCurrentPage] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', text: '' });

  const openModal = async (content) => {
    try {
      let modalData = {
        title: content.title,
        missionText: '',
        visionText: '',
        text: ''
      };

      if (content.missionFile && content.visionFile) {
        // Para Misión y Visión
        const [missionResponse, visionResponse] = await Promise.all([
          fetch(content.missionFile),
          fetch(content.visionFile)
        ]);

        modalData.missionText = await missionResponse.text();
        modalData.visionText = await visionResponse.text();
      } else if (content.textFile) {
        // Para Historia y Sellos Educativos
        const response = await fetch(content.textFile);
        if (!response.ok) throw new Error('Error al cargar el archivo de texto');
        modalData.text = await response.text();
      }

      setModalContent(modalData);
      setIsModalOpen(true);
    } catch (error) {
      console.error(error);
      alert('Hubo un problema al cargar el contenido.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-beige-50">
      <main className="flex-1 w-full flex flex-col items-center">
        <BannerSection />
        <ValuesSection openModal={openModal} />
        <TeamSection
          expandedDepartment={expandedDepartment}
          setExpandedDepartment={setExpandedDepartment}
          currentPage={currentPage}
          setCurrentPage={setCurrentPage}
        />
        <JoinUsSection />
      </main>

      {isModalOpen && (
        <SimpleModal
          content={modalContent}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </div>
  );
}

function BannerSection() {
    return (
      <section
        className="w-full py-12 md:py-20 flex items-center justify-center relative overflow-hidden animate-fadeIn"
        style={{
          minHeight: '500px',
          position: 'relative',
        }}
      >
        <div
          className="absolute inset-0 z-0"
          style={{
            backgroundImage: 'url(/01-images/banner.webp)',
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            filter: 'brightness(0.9)',
          }}
        />
        <div className="absolute inset-0 z-10 bg-gradient-to-b from-black/50 to-transparent" />
        <div className="container mx-auto px-6 md:px-8 py-12 relative z-20">
          <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl mb-6 drop-shadow-xl animate-fadeIn text-center">
            Conoce Nuestro Colegio
          </h1>
          <p className="mx-auto max-w-[800px] text-white text-lg md:text-xl mb-8 drop-shadow-xl animate-fadeIn delay-200 text-center">
            Descubre la historia, sellos educativos, misión y visión que nos definen como una institución educativa comprometida con la excelencia.
          </p>
        </div>
      </section>
    );
  }
  
  function TeamSection({ expandedDepartment, setExpandedDepartment, currentPage, setCurrentPage }) {
    const membersPerPage = 12;
  
    const changePage = (deptName, delta) => {
      const department = departments.find((d) => d.name === deptName);
      if (!department) return;
  
      const totalPages = Math.ceil(department.members.length / membersPerPage);
      setCurrentPage((prev) => {
        const current = prev[deptName] || 0;
        const newPage = Math.max(0, Math.min(current + delta, totalPages - 1));
        return { ...prev, [deptName]: newPage };
      });
    };
  
    return (
      <section className="w-full py-12 md:py-24 lg:py-32 bg-white">
        <div className="container mx-auto px-4 md:px-6">
          <h2 className="text-3xl font-bold tracking-tighter text-green-900 sm:text-4xl md:text-5xl text-center mb-8">
            Nuestro Equipo
          </h2>
          <div className="space-y-4">
            {departments.map((dept) => (
              <Department
                key={dept.name}
                department={dept}
                expandedDepartment={expandedDepartment}
                setExpandedDepartment={setExpandedDepartment}
                currentPage={currentPage}
                changePage={changePage}
              />
            ))}
          </div>
        </div>
      </section>
    );
  }
  
  function Department({ department, expandedDepartment, setExpandedDepartment, currentPage, changePage }) {
    const membersPerPage = 12;
    const currentDeptPage = currentPage[department.name] || 0;
  
    const startIndex = currentDeptPage * membersPerPage;
    const endIndex = startIndex + membersPerPage;
    const membersToShow = department.members.slice(startIndex, endIndex);
  
    const totalPages = Math.ceil(department.members.length / membersPerPage);
  
    return (
      <div className="border border-green-700 rounded-lg overflow-hidden">
        <button
          className="w-full p-4 flex justify-between items-center bg-green-800 hover:bg-green-700 transition-colors"
          onClick={() => setExpandedDepartment(expandedDepartment === department.name ? null : department.name)}
          aria-expanded={expandedDepartment === department.name}
        >
          <span className="text-xl font-bold text-white">{department.name}</span>
          {expandedDepartment === department.name ? (
            <ChevronUp className="h-6 w-6 text-white" />
          ) : (
            <ChevronDown className="h-6 w-6 text-white" />
          )}
        </button>
        {expandedDepartment === department.name && (
          <div className="p-4 bg-beige-50">
            <div className="grid gap-4 grid-cols-3 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4">
              {membersToShow.map((member) => (
                <Member key={member.name} member={member} />
              ))}
            </div>
            <div className="mt-4 flex justify-between items-center">
              <Button onClick={() => changePage(department.name, -1)} disabled={currentDeptPage === 0}>
                <ChevronLeft className="w-4 h-4" />
              </Button>
              <span className="text-green-800">
                Página {currentDeptPage + 1} de {totalPages}
              </span>
              <Button
                onClick={() => changePage(department.name, 1)}
                disabled={currentDeptPage === totalPages - 1}
              >
                <ChevronRight className="w-4 h-4" />
              </Button>
            </div>
          </div>
        )}
      </div>
    );
  }
  
  function Member({ member }) {
    return (
      <div className="flex flex-col items-center text-center">
        <div className="w-24 h-24 mb-2 overflow-hidden rounded-full">
          <img
            src={member.image || '/default-photo.png'}
            alt={member.name}
            className="object-cover w-full h-full"
          />
        </div>
        <h3 className="text-lg font-bold text-green-800">{member.name}</h3>
        <p className="text-sm text-green-700">{member.role}</p>
      </div>
    );
  }

  function JoinUsSection() {
    return (
      <section className="w-full py-12 md:py-24 lg:py-32 bg-beige-50">
        <div className="container mx-auto px-4 md:px-6 text-center">
          <h2 className="text-3xl font-bold tracking-tighter text-green-900 sm:text-4xl md:text-5xl">
            Únete a Nuestra Comunidad
          </h2>
          <p className="max-w-[700px] mx-auto text-green-800 md:text-xl lg:text-base xl:text-xl my-4">
            En Colegio Nuevo Milenio, creemos en el potencial de cada estudiante. ¡Sé parte de nuestra familia educativa!
          </p>
          <img
            src="/01-images/milenito.webp"
            alt="Milenito"
            className="mx-auto my-6 w-6/10 max-w-xs"
          />
          <div className="flex justify-center">
            <button
              className="bg-green-900 text-white hover:bg-green-800 text-lg px-8 py-3 shadow-xl hover:shadow-2xl transition-all hover:scale-105 w-full sm:w-auto rounded-md"
              onClick={() => window.location.href = 'mailto:informaciones@nmva.cl'}
            >
              Contáctanos
            </button>
          </div>
        </div>
      </section>
    );
  }
  
  function SimpleModal({ content, onClose }) {
    return (
      <div
        className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 px-8 z-50"
        onClick={onClose}
      >
        <div
          className="bg-white p-6 rounded shadow-lg max-w-3xl w-full max-h-[80vh] overflow-y-auto"
          onClick={(e) => e.stopPropagation()}
        >
          <h2 className="text-2xl font-bold mb-4 text-green-800 text-center">
            {content.title}
          </h2>
          
          {(content.missionText || content.visionText) && (
            <div className="flex flex-col md:flex-row justify-between space-y-4 md:space-y-0 md:space-x-4">
              {content.missionText && (
                <div className="md:w-1/2">
                  <h3 className="font-bold text-green-800">Misión</h3>
                  <p className="text-black">{content.missionText}</p>
                </div>
              )}
              {content.visionText && (
                <div className="md:w-1/2">
                  <h3 className="font-bold text-green-800">Visión</h3>
                  <p className="text-black">{content.visionText}</p>
                </div>
              )}
            </div>
          )}

          {content.text && (
            <div className="prose max-w-none">
              <p className="text-black">{content.text}</p>
            </div>
          )}

          <button
            className="mt-4 bg-green-900 text-white px-4 py-2 rounded hover:bg-green-800 transition-colors"
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    );
  }