import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ 
  children, 
  className = '', 
  variant = 'default', 
  href, 
  onClick,
  size = 'default',
  ...props 
}) => {
  // Estilos base y variantes
  const baseStyles = "inline-flex items-center justify-center rounded-md font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none";
  
  const variants = {
    default: "bg-green-900 text-white hover:bg-green-800",
    outline: "border-2 border-beige-100 bg-white/10 backdrop-blur-sm text-beige-100 hover:bg-white/20",
    secondary: "bg-green-100 text-green-900 hover:bg-green-200",
  };

  const sizes = {
    small: "text-sm px-3 py-1",
    default: "text-lg px-6 py-3",
    large: "text-xl px-8 py-4",
  };

  const combinedClassName = `${baseStyles} ${variants[variant]} ${sizes[size]} ${className}`;

  // Si hay un href, usar Link de react-router-dom
  if (href) {
    return (
      <Link 
        to={href} 
        className={combinedClassName}
        {...props}
      >
        {children}
      </Link>
    );
  }

  // Si no hay href, usar un botón normal
  return (
    <button 
      className={combinedClassName}
      onClick={onClick}
      {...props}
    >
      {children}
    </button>
  );
};

export default Button;