import React from 'react';
import { FileText, Download } from 'lucide-react';
import documents from '@/data/documents';

const Documentos = () => {
  // Agrupar documentos por categoría
  const groupedDocuments = documents.reduce((acc, doc) => {
    if (!acc[doc.category]) {
      acc[doc.category] = [];
    }
    acc[doc.category].push(doc);
    return acc;
  }, {});

  return (
    <div className="flex flex-col min-h-screen bg-beige-50">
      {/* Contenido principal */}
      <main className="flex-1 w-full flex flex-col items-center py-12 px-6 md:px-12">
        <h1 className="text-4xl font-bold text-green-900 mb-8 text-center">
          Documentos
        </h1>
        <p className="text-lg text-green-700 text-center mb-12">
          Aquí encontrarás todos los documentos importantes relacionados con los reglamentos, manuales y calendarios escolares.
        </p>

        <section className="w-full max-w-4xl">
          {Object.entries(groupedDocuments).map(([category, docs]) => (
            <div key={category} className="mb-8">
              <h2 className="text-2xl font-bold text-green-800 mb-4">{category}</h2>
              <div className="grid gap-6">
                {docs.map((doc) => (
                  <DocumentCard key={doc.id} document={doc} />
                ))}
              </div>
            </div>
          ))}
        </section>
      </main>
    </div>
  );
};

// Componente DocumentCard
const DocumentCard = ({ document }) => {
  return (
    <div className="flex justify-between items-center p-4 bg-white border border-green-200 rounded-lg shadow hover:shadow-lg transition-shadow duration-200">
      <div className="flex items-center gap-4">
        <FileText className="h-6 w-6 text-green-900" />
        <div>
          <h2 className="text-lg font-bold text-green-800">{document.title}</h2>
          <p className="text-sm text-green-700">{document.category}</p>
        </div>
      </div>
      <a
        href={document.fileUrl}
        download
        className="flex items-center gap-2 bg-green-900 text-white text-sm px-4 py-2 rounded-lg shadow hover:bg-green-800 transition-colors"
      >
        <Download className="h-4 w-4" />
        Descargar
      </a>
    </div>
  );
};

export default Documentos;