import { useState } from 'react';
import Button from './Button';

export default function ContactForm({ variant = "default" }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [status, setStatus] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    try {
      const response = await fetch('https://formspree.io/f/xdkkppzj', {
        method: 'POST',
        body: new FormData(form),
        headers: {
          Accept: 'application/json'
        }
      });
      
      if (response.ok) {
        setStatus('success');
        form.reset();
        setTimeout(() => {
          setIsModalOpen(false);
          setStatus('');
        }, 2000);
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    }
  };

  return (
    <>
      <Button variant={variant} onClick={() => setIsModalOpen(true)}>
        Contáctanos
      </Button>

      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full m-4">
            <h3 className="text-2xl font-bold mb-4 text-green-900">Contáctanos</h3>
            
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-green-800">
                  Nombre
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  className="mt-1 block w-full rounded-md border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-green-800">
                  Correo Electrónico
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  className="mt-1 block w-full rounded-md border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
              </div>

              <div>
                <label htmlFor="message" className="block text-sm font-medium text-green-800">
                  Mensaje
                </label>
                <textarea
                  name="message"
                  id="message"
                  rows={4}
                  required
                  className="mt-1 block w-full rounded-md border-green-300 shadow-sm focus:border-green-500 focus:ring-green-500"
                />
              </div>

              {status === 'success' && (
                <p className="text-green-600">¡Mensaje enviado con éxito!</p>
              )}
              
              {status === 'error' && (
                <p className="text-red-600">Hubo un error al enviar el mensaje. Por favor, intenta nuevamente.</p>
              )}

              <div className="flex gap-4">
                <Button 
                  type="submit" 
                  variant="default" 
                  className="w-full bg-green-900 hover:bg-green-800"
                >
                  Enviar Mensaje
                </Button>
                <Button 
                  type="button"
                  variant="outline" 
                  onClick={() => setIsModalOpen(false)}
                  className="w-full border-green-900 text-green-900 hover:bg-green-50"
                >
                  Cerrar
                </Button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  );
}