const departments = [
    {
      name: "Equipo de dirección",
      members: [
        {
          name: "Juan Pablo Yuivar",
          role: "Director",
          image: "/02-images-funcionarios/juanpablo-yuivar.webp",
        },
        {
          name: "Rubén Collao",
          role: "Sostenedor",
          image: "/02-images-funcionarios/ruben-collao.webp",
        },
        {
          name: "Mariela Aguilera",
          role: "Psicóloga y Orientadora",
          image: "/02-images-funcionarios/mariela-aguilera.webp",
        },
        {
          name: "María Patricia Fernández",
          role: "Coordinadora de media",
          image: "/02-images-funcionarios/mariapatricia-fernandez.webp",
        },
        {
          name: "Cristián Calderón",
          role: "Inspector General",
          image: "/02-images-funcionarios/cristian.webp",
        },
        {
          name: "Esteban Alegría",
          role: "Coordinador de basica",
          image: "/02-images-funcionarios/esteban-alegria.webp",
        },
        {
          name: "Daniela Contreras",
          role: "Asesora UTP",
          image: "/02-images-funcionarios/daniela-contreras.webp",
        },
        {
          name: "Gisel Orellana",
          role: "Coordinadora de prebasica",
          image: "/02-images-funcionarios/gisel-orellana.webp",
        },
        // Agrega más miembros según corresponda
      ],
    },

    {
      name: "Administración",
      members: [
        {
          name: "Héctor Nanjari",
          role: "Encargado de Biblioteca",
          image: "/02-images-funcionarios/hector-nanjari.webp",
        },
        {
          name: "Constanza Collao",
          role: "Encargada Redes Sociales",
          image: "/02-images-funcionarios/constanza-collao.webp",
        },
        {
          name: "Natalia Sánchez",
          role: "Secretaria U.T.P.",
          image: "/02-images-funcionarios/natalia-sanchez.webp",
        },
        {
          name: "Jacqueline Donoso",
          role: "Secretaria Finanzas",
          image: "/02-images-funcionarios/jacqueline-donoso.webp",
        },
        {
          name: "Marcela Bahamondes",
          role: "Jefa Departamento de Administración y Finanzas",
          image: "/02-images-funcionarios/marcela-bahamondes.webp",
        },
        // Agrega más miembros
      ],
    },

    {
      name: "Inspectoría",
      members: [

        {
          name: "Lorena Sanchez",
          role: "Inspectora",
          image: "/02-images-funcionarios/lorena-sanchez.webp",
        },
        {
          name: "Patricia Ojeda",
          role: "Inspectora Paradocente",
          image: "/02-images-funcionarios/patricia-ojeda.webp",
        },
        {
          name: "Angélica Órdenes",
          role: "Inspectora",
          image: "/02-images-funcionarios/angelica-ordenes.webp",
        },
        {
          name: "Rodrigo Paz",
          role: "Inspector Docente Media",
          image: "/02-images-funcionarios/rodrigo-paz.webp",
        },
        {
          name: "Jeanette Cortez",
          role: "Inspectora",
          image: "/02-images-funcionarios/jeanette-cortez.webp",
        },
        {
          name: "Angélica Pérez",
          role: "Recepción",
          image: "/02-images-funcionarios/angelica-perez.webp",
        },
        {
          name: "Georgette Aguilar",
          role: "Inspectora",
          image: "/02-images-funcionarios/georgette-aguilar.webp",
        },
        {
          name: "Glenda Rebolledo",
          role: "Inspectora",
          image: "/02-images-funcionarios/glenda-rebolledo.webp",
        },
        {
          name: "Sabrina Salinas",
          role: "Inspectora Docente Nivel Prebásica a 6° Básico",
          image: "/02-images-funcionarios/sabrina-salinas.webp",
        },
        // Agrega más miembros
      ],
    },
    
    {
      name: "Equipo PIE",
      members: [
        {
          name: "Denisse Carrillo",
          role: "Educadora Diferencial",
          image: "/02-images-funcionarios/denisse-carrillo.webp",
        },
        {
          name: "María Teresa Cartes",
          role: "Psicopedagoga",
          image: "/02-images-funcionarios/maria-teresa-cartes.webp",
        },
        {
          name: "José Miguel Vásquez",
          role: "Psicólogo",
          image: "/02-images-funcionarios/josemiguel-vasquez.webp",
        },
        {
          name: "Javier Castro",
          role: "Terapeuta Ocupacional",
          image: "/02-images-funcionarios/javier-castro.webp",
        },
        {
          name: "Nicole Ávila",
          role: "Fonoaudióloga",
          image: "/02-images-funcionarios/nicole-avila.webp",
        },
        {
          name: "Guissella Muñoz",
          role: "Fonoaudióloga",
          image: "/02-images-funcionarios/guissella-munoz.webp",
        },
        {
          name: "Ingrid Vera",
          role: "Psicóloga",
          image: "/02-images-funcionarios/ingrid-vera.webp",
        },
        {
          name: "Katherine Mancilla",
          role: "Educadora Diferencial",
          image: "/02-images-funcionarios/katherine-mancilla.webp",
        },
        {
          name: "Madelaine Abarca",
          role: "Fonoaudióloga PIE",
          image: "/02-images-funcionarios/madelaine-abarca.webp",
        },
        {
          name: "Marcela Otero",
          role: "Educadora Diferencial PIE",
          image: "/02-images-funcionarios/marcela-otero.webp",
        },
        // Agrega más miembros
      ],
    },

    {
      name: "Profesores y profesoras",
      members: [
        {
          name: "Juanita Fernández",
          role: "Profesora Educación General Básica",
          image: "/02-images-funcionarios/juanita-fernandez.webp",
        },
        {
          name: "Cynthia Escobar",
          role: "Profesora de Lenguaje",
          image: "/02-images-funcionarios/cynthia-escobar.webp",
        },
        {
          name: "Glenda Ponce",
          role: "Educadora de Párvulos",
          image: "/02-images-funcionarios/glenda-ponce.webp",
        },
        {
          name: "Cristian Molina",
          role: "Profesor de Historia",
          image: "/02-images-funcionarios/cristian-molina.webp",
        },
        {
          name: "Pamela Rossel",
          role: "Profesora de Inglés",
          image: "/02-images-funcionarios/pamela-rossel.webp",
        },
        {
          name: "Fernanda Arriagada",
          role: "Profesora Educación General Básica",
          image: "/02-images-funcionarios/fernanda-arriagada.webp",
        },
        {
          name: "Carolina Álvarez",
          role: "Profesora de Educación General Básica",
          image: "/02-images-funcionarios/carolina-alvarez.webp",
        },
        {
          name: "Andrés Echeverria",
          role: "Profesor de Biología",
          image: "/02-images-funcionarios/andres-echeverria.webp",
        },
        {
          name: "Francisco Guerrero",
          role: "Profesor de Historia",
          image: "/02-images-funcionarios/francisco-guerrero.webp",
        },
        {
          name: "Joaquín Oses",
          role: "Profesor de Educación Física",
          image: "/02-images-funcionarios/joaquin-oses.webp",
        },
        {
          name: "Carla Román",
          role: "Profesora Educación General Básica",
          image: "/02-images-funcionarios/carla-roman.webp",
        },
        {
          name: "Felipe Muñoz",
          role: "Profesor de Matemática",
          image: "/02-images-funcionarios/felipe-muñoz.webp",
        },
        {
          name: "Javiera Arrue",
          role: "Educadora de Párvulos",
          image: "/02-images-funcionarios/javiera-arrue.webp",
        },
        {
          name: "Gisela Orellana",
          role: "Profesora Educación General Básica",
          image: "/02-images-funcionarios/gisela-orellana.webp",
        },
        {
          name: "Mauricio Rossé",
          role: "Profesor de Lenguaje",
          image: "/02-images-funcionarios/mauricio-rosse.webp",
        },
        {
          name: "Aquiles Morán",
          role: "Profesor Educación General Básica",
          image: "/02-images-funcionarios/aquiles-moran.webp",
        },
        {
          name: "Catalina Medina",
          role: "Profesora Educación General Básica",
          image: "/02-images-funcionarios/catalina-medina.webp",
        },
        {
          name: "María José Jatib",
          role: "Educadora de Párvulos",
          image: "/02-images-funcionarios/maria-jose-jatib.webp",
        },
        {
          name: "Viviana Aedo",
          role: "Profesora de Filosofía",
          image: "/02-images-funcionarios/viviana-aedo.webp",
        },
        {
          name: "Rosa Zamora",
          role: "Profesora de Artes Visuales",
          image: "/02-images-funcionarios/rosa-zamora.webp",
        },
        {
          name: "Johanna Medina",
          role: "Profesora de Educación Musical",
          image: "/02-images-funcionarios/johanna-medina.webp",
        },
        {
          name: "Nicole Muñoz",
          role: "Profesora de Filosofía",
          image: "/02-images-funcionarios/nicole-muñoz.webp",
        },
        {
          name: "Jannett Núñez",
          role: "Profesora de Educación Física",
          image: "/02-images-funcionarios/jannett-nuñez.webp",
        },
        {
          name: "Liliana Adaros",
          role: "Educadora de Párvulos",
          image: "/02-images-funcionarios/liliana-adaros.webp",
        },
        {
          name: "Yanina Pino",
          role: "Profesora de Educación Física",
          image: "/02-images-funcionarios/yanina-pino.webp",
        },
        {
          name: "Rocío Pavez",
          role: "Profesora de Ciencias Naturales",
          image: "/02-images-funcionarios/rocio-pavez.webp",
        },
        {
          name: "Eva Jaure",
          role: "Profesora de Educación General Básica",
          image: "/02-images-funcionarios/eva-jaure.webp",
        },
        {
          name: "Carolina Orellana",
          role: "Profesora de Inglés",
          image: "/02-images-funcionarios/carolina-orellana.webp",
        },
        {
          name: "Magaly Cano",
          role: "Profesora de Educación General Básica",
          image: "/02-images-funcionarios/magaly-cano.webp",
        },
        {
          name: "Carolina Ojeda",
          role: "Profesora de Inglés",
          image: "/02-images-funcionarios/carolina-ojeda.webp",
        },
        {
          name: "Cristóbal Román",
          role: "Profesor de Educación Física",
          image: "/02-images-funcionarios/cristobal-roman.webp",
        },
        {
          name: "Ángela Huichalaf",
          role: "Profesora de Educación Tecnológica",
          image: "/02-images-funcionarios/angela-huichalaf.webp",
        },
        {
          name: "Hernán Ramírez",
          role: "Profesor de Matemáticas y Física",
          image: "/02-images-funcionarios/hernan-ramirez.webp",
        },
        // Agrega más miembros
      ],
    },
  ]
  // Otros departamentos...
  export default departments
  