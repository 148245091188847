import React, { useState, useEffect } from 'react';
import { BookOpen, Users, GraduationCap } from 'lucide-react';
import Button from '@/components/Button';
import { newsContent } from '@/data/news';
import ContactForm from '@/components/ContactForm';

export default function Home() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const [isNewsModal, setIsNewsModal] = useState(false);

  const openModal = (type) => {
    if (type === "news") {
      setModalContent(newsContent);
      setIsNewsModal(true);
    } else {
      setModalContent([{ title: "Información", moreText: "Este es un cuadro de texto simple." }]);
      setIsNewsModal(false);
    }
    setIsModalOpen(true);
  };

  return (
    <main className="flex-1 w-full flex flex-col items-center">
      <BannerSection />
      <InformationSection openModal={openModal} />
      <JoinUsSection />
      
      {/* Modales */}
      {isModalOpen && (
        isNewsModal ? (
          <NewsModal
            news={modalContent}
            onClose={() => setIsModalOpen(false)}
          />
        ) : (
          <SimpleModal
            content={modalContent[0]}
            onClose={() => setIsModalOpen(false)}
          />
        )
      )}
    </main>
  );
}

// Sección del Banner
const BannerSection = () => {
  return (
    <section
      className="w-full py-12 md:py-20 flex items-center justify-center relative overflow-hidden animate-fadeIn"
      style={{ minHeight: "500px" }}
    >
      <div
        className="absolute inset-0 z-0"
        style={{
          backgroundImage: "url(/01-images/banner.webp)",
          backgroundPosition: "center",
          backgroundSize: "cover",
          filter: "brightness(0.9)",
        }}
      />
      <div className="absolute inset-0 z-10 bg-gradient-to-b from-black/50 to-transparent" />
      <div className="container mx-auto px-6 md:px-8 py-12 relative z-20">
        <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl mb-6 drop-shadow-xl animate-fadeIn text-center">
          Bienvenidos a Colegio Nuevo Milenio
        </h1>
        <p className="mx-auto max-w-[800px] text-white text-lg md:text-xl mb-8 drop-shadow-xl animate-fadeIn delay-200 text-center">
          Formando líderes para el futuro con educación de calidad y valores sólidos.
        </p>
        <div className="flex flex-col sm:flex-row sm:justify-center space-y-4 sm:space-y-0 sm:space-x-4 mt-6 animate-fadeIn delay-300">
          <Button href="/quienes-somos" variant="default">
            Quiénes Somos
          </Button>
          <ContactForm variant="outline" />
        </div>
      </div>
    </section>
  );
};

// Sección de Información
const InformationSection = ({ openModal }) => {
  const [hasNewNews, setHasNewNews] = useState(false);

  useEffect(() => {
    // Verificar si hay noticias nuevas
    const hasNew = newsContent.some(news => news.isNew);
    setHasNewNews(hasNew);
  }, []);

  return (
    <section className="w-full py-20 bg-white">
      <div className="container mx-auto px-6 md:px-8 text-center">
        <h2 className="text-3xl font-bold tracking-tight text-green-900 mb-6">
          Información Destacada
        </h2>
        <div className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
          {/* Card Noticias */}
          <div className="flex flex-col items-center text-center p-8 rounded-xl border border-green-100 shadow-lg hover:shadow-xl transition-all duration-300 relative">
            {hasNewNews && (
              <div className="absolute -top-3 -right-3 px-3 py-1 bg-red-500 text-white text-xs font-bold rounded-full animate-pulse">
                Nueva
              </div>
            )}
            <BookOpen className="h-14 w-14 text-green-900 mb-4 hover:rotate-6 transition-transform" />
            <h3 className="text-xl font-bold mb-3 text-green-800">Noticias</h3>
            <p className="text-base text-green-700">
              Entérate de las últimas noticias y eventos del colegio.
            </p>
            <Button
              variant="default"
              className="mt-4"
              onClick={() => openModal("news")}
            >
              Ver más
            </Button>
          </div>

          {/* Card Lista de espera */}
          <div className="flex flex-col items-center text-center p-8 rounded-xl border border-green-100 shadow-lg hover:shadow-xl transition-all duration-300">
            <Users className="h-14 w-14 text-green-900 mb-4 hover:rotate-6 transition-transform" />
            <h3 className="text-xl font-bold mb-3 text-green-800">Matrículate</h3>
            <p className="text-base text-green-700">
              Pincha aquí para ver los requisitos, el proceso de inscripción y la lista de espera.
            </p>
            <Button
              variant="default"
              className="mt-4"
              onClick={() => window.open('https://www.sistemadeadmisionescolar.cl/', '_blank')}
            >
              Ver más
            </Button>
          </div>

          {/* Card Recorrido Virtual */}
          <div className="flex flex-col items-center text-center p-8 rounded-xl border border-green-100 shadow-lg hover:shadow-xl transition-all duration-300">
            <GraduationCap className="h-14 w-14 text-green-900 mb-4 hover:rotate-6 transition-transform" />
            <h3 className="text-xl font-bold mb-3 text-green-800">Recorrido Virtual</h3>
            <p className="text-base text-green-700">
              Pincha aquí para ver el recorrido virtual del colegio.
            </p>
            <Button
              variant="default"
              className="mt-4"
              onClick={() => window.open('https://www.youtube.com/watch?v=iMIfZ1qCLvM', '_blank')}
            >
              Ver más
            </Button>
          </div>
        </div>
      </div>
    </section>
  );
};

// Sección Únete
const JoinUsSection = () => {
  return (
    <section className="w-full py-12 md:py-24 lg:py-32 bg-beige-50">
      <div className="container mx-auto px-4 md:px-6 text-center">
        <h2 className="text-3xl font-bold tracking-tighter text-green-900 sm:text-4xl md:text-5xl">
          Únete a Nuestra Comunidad
        </h2>
        <p className="max-w-[700px] mx-auto text-green-800 md:text-xl lg:text-base xl:text-xl my-4">
          En Colegio Nuevo Milenio, creemos en el potencial de cada estudiante. 
          ¡Sé parte de nuestra familia educativa!
        </p>
        
        {/* Contenedor flex para la imagen y el botón */}
        <div className="flex flex-col items-center gap-4">
          <img
            src="/01-images/milenito.webp"
            alt="Milenito"
            className="w-6/10 max-w-xs my-4"
          />
          <ContactForm variant="default" />
        </div>
      </div>
    </section>
  );
};

function NewsModal({ news, onClose }) {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % news.length);
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + news.length) % news.length);
  };

  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg max-w-2xl w-full max-h-[80vh] overflow-y-auto m-4"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-bold mb-2 text-green-800">{news[currentIndex].title}
          {news[currentIndex].isNew && (
            <span className="ml-2 px-2 py-1 text-xs font-semibold rounded-full bg-red-500 text-white">
              Nueva
            </span>
          )}
        </h2>
        <p className="text-sm text-gray-500 mb-4">{news[currentIndex].date}</p>
        <div className="flex flex-col md:flex-row gap-4">
          {news[currentIndex].image && (
            <img
              src={news[currentIndex].image}
              alt={news[currentIndex].title}
              className="w-full md:w-48 h-48 object-cover rounded"
            />
          )}
          <div className="flex-1">
            <p className="text-black whitespace-pre-wrap">{news[currentIndex].content}</p>
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <button
            onClick={handlePrev}
            className="text-gray-600 hover:text-gray-900 transition-colors px-4 py-2 rounded-md hover:bg-gray-100"
          >
            ← Anterior
          </button>
          <button
            onClick={handleNext}
            className="text-gray-600 hover:text-gray-900 transition-colors px-4 py-2 rounded-md hover:bg-gray-100"
          >
            Siguiente →
          </button>
        </div>
        <Button
          variant="default"
          className="w-full mt-4"
          onClick={onClose}
        >
          Cerrar
        </Button>
      </div>
    </div>
  );
}

function SimpleModal({ content, onClose }) {
  return (
    <div
      className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      onClick={onClose}
    >
      <div
        className="bg-white p-6 rounded-lg shadow-lg max-w-lg w-full m-4"
        onClick={(e) => e.stopPropagation()}
      >
        <h2 className="text-2xl font-bold mb-4 text-green-800">{content.title}</h2>
        <p className="text-green-700">{content.moreText}</p>
        <Button
          variant="default"
          className="w-full mt-4"
          onClick={onClose}
        >
          Cerrar
        </Button>
      </div>
    </div>
  );
}