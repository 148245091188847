export const newsContent = [
    {
      id: 1,
      title: "Inicio del Año Escolar 2025",
      date: "2025-02-26",
      content:  `Educación Básica y Media  
      - 2° básico: 08:30 - 12:00 hrs  
      - 3° a 4° básico: 08:30 - 12:30 hrs  
      - 5° básico a 4° medio: 08:30 - 13:00 hrs`,
      content2: "",
      image: "/03-news/inicio-escolar1.webp",
      isNew: false
    },
    {
      id: 2,
      title: "Inicio del Año Escolar 2025",
      date: "2025-02-26",
      content: `Estimadas familias del Colegio Nuevo Milenio,  
Les informamos que el inicio del año escolar 2025 será el miércoles 5 de marzo, con horarios especiales del 5 al 7 de marzo:

Pre-Kinder y Kinder  
- Jornada de mañana y 1° básico (alumnos nuevos): 08:30 - 10:00 hrs  
- Jornada de tarde: 13:45 - 15:30 hrs  
- 1° básico (alumnos antiguos): 10:30 - 12:00 hrs`,
      image: "/03-news/inicio-escolar2.webp",
      isNew: false
    },
    {
      id: 3,
      title: "Nuevos numeros de contacto",
      date: "2025-03-31",
      content: `Estimadas familias del Colegio Nuevo Milenio,  
Les informamos que hay nuevos numeros telefónicos para comunicarse con nosotros:

- Secretaría: 56 9 9997 3396
- Recaudación: 56 9 8723 9659
- Recepción: 56 9 8723 7809 
- Inspectoría: 56 9 9997 3482`,
      image: "/03-news/numeros.webp",
      isNew: true
    },
  ];
  
  export default newsContent;


  `Educación Básica y Media  
- 2° básico: 08:30 - 12:00 hrs  
- 3° a 4° básico: 08:30 - 12:30 hrs  
- 5° básico a 4° medio: 08:30 - 13:00 hrs`