import { Target } from "lucide-react";
import ValueCard from "./ValuesCard";

export default function MisionVisionCard({ openModal }) {
  return (
    <ValueCard
      icon={Target}
      title="Misión y Visión"
      description="Formar líderes integrales, capaces de enfrentar los desafíos del siglo XXI con conocimiento, ética y responsabilidad social."
      onClick={() => openModal({ 
        title: "Misión y Visión", 
        missionFile: "/06-textos/mision.txt",
        visionFile: "/06-textos/vision.txt"
      })}
    />
  );
}