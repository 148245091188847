import { BookOpen } from "lucide-react";
import ValueCard from "./ValuesCard";

export default function NuestraHistoriaCard({ openModal }) {
  return (
    <ValueCard
      icon={BookOpen}
      title="Nuestra Historia"
      description="Fundado en 2002, Colegio Nuevo Milenio ha sido un pilar en la educación de nuestra comunidad por más de 22 años."
      onClick={() => openModal({ 
        title: "Nuestra Historia", 
        textFile: "/06-textos/historia.txt"
      })}
    />
  );
}