import React from 'react';
import { FileText, Download } from 'lucide-react';
import supplies from '@/data/utils';

const ListaDeUtiles = () => {
  return (
    <div className="flex flex-col min-h-screen bg-beige-50">
      {/* Contenido principal */}
      <main className="flex-1 w-full flex flex-col items-center py-12 px-6 md:px-12">
        <h1 className="text-4xl font-bold text-green-900 mb-8 text-center">
          Lista de Útiles
        </h1>
        <p className="text-lg text-green-700 text-center mb-12">
          Aquí encontrarás las listas de útiles escolares para cada curso.
        </p>

        <section className="w-full max-w-4xl">
          <div className="grid gap-6">
            {supplies.map((supply) => (
              <SupplyCard key={supply.id} supply={supply} />
            ))}
          </div>
        </section>
      </main>
    </div>
  );
};

// Componente SupplyCard
const SupplyCard = ({ supply }) => {
  return (
    <div className="flex justify-between items-center p-4 bg-white border border-green-200 rounded-lg shadow hover:shadow-lg transition-shadow duration-200">
      <div className="flex items-center gap-4">
        <FileText className="h-6 w-6 text-green-900" />
        <div>
          <h2 className="text-lg font-bold text-green-800">{supply.title}</h2>
          <p className="text-sm text-green-700">{supply.course}</p>
        </div>
      </div>
      <a
        href={supply.fileUrl}
        download
        className="flex items-center gap-2 bg-green-900 text-white text-sm px-4 py-2 rounded-lg shadow hover:bg-green-800 transition-colors"
      >
        <Download className="h-4 w-4" />
        Descargar
      </a>
    </div>
  );
};

export default ListaDeUtiles;