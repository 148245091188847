import { Award } from "lucide-react";
import ValueCard from "./ValuesCard";

export default function SellosEducativosCard({ openModal }) {
  return (
    <ValueCard
      icon={Award}
      title="Nuestro Sello Educativo"
      description="Respeto, solidaridad, empatía y responsabilidad son los pilares que guían nuestra labor educativa."
      onClick={() => openModal({ 
        title: "Educar en los Principios de la Libertad", 
        textFile: "/06-textos/sellos-educativos.txt"
      })}
    />
  );
}